/* eslint-disable no-undef */

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';

import Root from 'components/routes/Root';
import './index.css';
import App from 'components/App';
import SuspenseLoader from 'components/common/loaders/SuspenseLoader';
import * as serviceWorker from './serviceWorker';
import DocumentTitle from 'components/common/DocumentTitle';
import ApplicationProvider from 'providers/ApplicationProvider';

ReactDOM.render(
  <ApplicationProvider>
    <App>
      <React.Suspense fallback={<SuspenseLoader />}>
        <DocumentTitle />
        <Root />
      </React.Suspense>
    </App>
  </ApplicationProvider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
