/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useContext } from 'react';
import { Router, navigate, RouteComponentProps } from '@reach/router';

import { navigateTo } from 'utils/navigation';
import { isACapitalAdvisorUser } from 'utils/url';

import ApplicationContext from 'contexts/ApplicationContext';
import ErrorPage from 'components/common/errors/ErrorPage';
import ErrorBoundary from 'components/common/errors/ErrorBoundary';
import EnvironmentRibbon from 'components/common/EnvironmentRibbon';
import ContactInfoPage from 'components/pages/ContactInfoPage';
import BusinessRegistrationPage from 'components/pages/BusinessRegistrationPage';
import BusinessActivityPage from 'components/pages/BusinessActivityPage';
import UnqualifiedPage from 'components/pages/UnqualifiedPage';
import ConfirmationPage from 'components/pages/ConfirmationPage';
import OtpPage from 'components/pages/OtpPage';

export interface RouteItemProps {
  path: string;
  public: boolean;
  component: React.ReactNode;
  permitted: boolean;
}

export interface RouteProps {
  routes: RouteItemProps[];
}

type RouteTypeProps = RouteItemProps;

// Lazy load components so that it won't
// load everything on initial load
const HomePage = React.lazy(() => import('components/pages/HomePage'));

export const onboardingBaseUri =
  process.env.REACT_APP_API_BASE_ENDPOINT || 'http://localhost:3000'; // eslint-disable-line no-undef

const commonRoutes = [
  {
    path: '/',
    public: false,
    component: HomePage,
    permitted: true
  },
  {
    path: '/pre-qualification-form',
    public: false,
    // Temporarily replacing below to compare OB to FCC lead flow
    // without the Product Story
    // component: ProductStoryPage,
    component: BusinessRegistrationPage,
    permitted: true
  },
  {
    path: '/pre-qualification-form/step-1',
    public: false,
    component: BusinessRegistrationPage,
    permitted: true
  },
  {
    path: '/pre-qualification-form/step-2',
    public: false,
    component: BusinessActivityPage,
    permitted: true
  },
  {
    path: '/pre-qualification-form/step-3',
    public: false,
    component: ContactInfoPage,
    permitted: true
  },
  {
    path: '/pre-qualification-form/step-4',
    public: false,
    component: OtpPage,
    permitted: true
  },
  {
    path: '/pre-qualification-form/unqualified',
    public: false,
    component: UnqualifiedPage,
    permitted: true
  }
];

const inboundCustomerRoutes = [
  ...commonRoutes,
  {
    path: '/pre-qualification-form/confirmation',
    public: false,
    component: ConfirmationPage,
    permitted: true
  }
];

const capitalAdvisorRoutes = [...commonRoutes];

const buildRoutes = (): RouteItemProps[] => {
  if (isACapitalAdvisorUser()) {
    return capitalAdvisorRoutes;
  } else {
    return [...commonRoutes, ...inboundCustomerRoutes];
  }
};

export const unauthorize = () => {
  navigate(`${onboardingBaseUri}/central/admin_users/sign_in`);
};

// In case we will be showing not private route in the future
const PublicRoute = ({ component, ...rest }: RouteItemProps) => {
  const Component = component as React.FC<RouteComponentProps>;

  return <Component {...rest} />;
};

const PrivateRoute = ({ component, path, ...rest }: RouteTypeProps) => {
  const { stepNumber, pageHardRefreshed } = useContext(ApplicationContext);
  const Component = component as React.FC<RouteComponentProps>;

  function handleNavigateToStepOne(): void {
    // Temporarily disabling below to compare OB to FCC lead flow
    // without the Product Story
    // if (!isACapitalAdvisorUser() && stepNumber === 1 && !seenProductStory) {
    //   navigateTo('/pre-qualification-form');
    // }

    if (stepNumber !== 1 && pageHardRefreshed) {
      navigateTo('/pre-qualification-form/step-1');
    }
  }

  // Handle redirect to step 1 if user goes to other steps or pages
  useEffect(handleNavigateToStepOne, [stepNumber]);

  return <Component path={path} {...rest} />;
};

const RoutesBuilder: React.FC = () => {
  const routes = buildRoutes();

  // Prevents flicker showing of not found page
  if (routes.length === 0) return <div data-testid="routes-builder" />;

  return (
    <div data-testid="routes-builder">
      <EnvironmentRibbon />
      <ErrorBoundary>
        <Router>
          {routes.map((route: RouteItemProps, i: number) =>
            route.public ? (
              <PublicRoute key={i} {...route} />
            ) : (
              <PrivateRoute key={i} {...route} />
            )
          )}
          <ErrorPage type="pageNotFound" styleType="large" default />
        </Router>
      </ErrorBoundary>
    </div>
  );
};

export default RoutesBuilder;
