import React, { useContext, useEffect } from 'react';

import ApplicationContext from 'contexts/ApplicationContext';
import { RouteComponentProps } from '@reach/router';
import querystring from 'query-string';
import { UTMInformation } from 'interfaces/onboarding';

import BuildRoutes from './RoutesHelper';

const Root: React.FC<RouteComponentProps> = () => {
  const { setUTMInformationValue } = useContext(ApplicationContext);

  function handleUrlParams(): void {
    const parsedQuery: UTMInformation = {
      ...querystring.parse(window.location.search)
    };
    setUTMInformationValue(parsedQuery);
  }

  // Url params
  useEffect(handleUrlParams, []);

  return (
    <div className="ds-container">
      <div data-testid="root-component">
        <BuildRoutes />
      </div>
    </div>
  );
};

export default Root;
